import React from "react"

export const TOP = {
  src: require("../../../static/assets/img/proj/photos/1.jpg"),
  title: "Hero section photo",
  alt: "team members",
}

export const ESPA = {
  src: require("../../../static/assets/img/proj/espa.png"),
  title: "Espa Logo",
  alt: "Espa Logo",
}

export const BUILD_TYPO_2 = {
  src: require("../../../static/assets/img/proj/photos/2.jpg"),
  alt: "Rion Bridge",
}

export const CV_1 = {
  src: require("../../../static/assets/img/proj/photos/cv/1.png"),
  alt: "University college london logo",
}

export const CV_2 = {
  src: require("../../../static/assets/img/proj/photos/cv/2.png"),
  alt: "observatory logo",
}