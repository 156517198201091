import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import styles from "./header.module.css"
import favicon16 from "../../static/assets/img/favicon/favicon.ico"
import favicon64 from "../../static/assets/img/favicon/favicon.png"
import favicon32 from "../../static/assets/img/favicon/favicon-32x32.png"

const Header = () => (
  <div>
    <Helmet
      htmlAttributes={{ lang : 'el' }}
      // htmlAttributes={[{"lang": "el"}]}
      link={[
        { rel: "icon", type: "image/ico", sizes: "16x16", href: `${favicon16}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
      ]}
    >
      <link href={"./assets/css/pe-icon-7-stroke.css"} rel="stylesheet" type="text/css" media="all"/>
      <link href={"./assets/css/bootstrap.min.css"} rel="stylesheet" type="text/css" media="all"/>
      <link href="./assets/css/flexslider.min.css" rel="stylesheet" type="text/css" media="all"/>
      <link href="./assets/css/lightbox.min.css" rel="stylesheet" type="text/css" media="all"/>
      <link href="./assets/css/theme.css" rel="stylesheet" type="text/css" media="all"/>
      <link href="./assets/css/custom.css" rel="stylesheet" type="text/css" media="all"/>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
            integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
            crossOrigin="anonymous"/>
            <link
        href="http://fonts.googleapis.com/css?family=Roboto:100,400,300,700,400italic,500%7CMontserrat:400,700"
        rel="stylesheet" type="text/css"/>
      {/*<title></title>*/}
      <title>Sofia Pappa</title>
    </Helmet>
    <div className="nav-container" style={{ maxWidth: "initial" }}>

      <nav className={"nav-1 " + styles.nav}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <ul className={styles.menu}>
                <li>
                  <Link to="/">Αρχικη</Link>
                </li>
                <li>
                  <Link to="/about">CV</Link>
                </li>
                <li>
                  <a href={require('../../static/assets/webpraxi.pdf')} target="_blank" rel="noreferrer noopener">ΠΡΑΞΗ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
)

export default Header
