import React from "react"
import styles from "./hero.module.css"
import { TOP } from "./images"


const Hero = (props) => {
  const { children, small } = props

  return (
    <section className="hero-slider large-image">
      <ul className="slides">
        <li style={small ? { height: 300 } : {}}>
          <div className={"background-image-holder overlay " + styles.hero} style={{ opacity: 1 }}>
            <img {...TOP} className={styles.hero__image}/>
          </div>

          <div className="vertical-align">
            <div className={styles.hero__text}>
              <div className="text-center">
                <div className={styles.hero__textH1}>ΣΟΦΙΑ ΠΑΠΠΑ</div>
                {children ? children
                  :
                  <h4 className="text-white">
                    Υπηρεσίες μελετών χημικού μηχανικού, υπηρεσίες εκπόνησης χημικοτεχνικών μελετών, υπηρεσίες χημικών
                    δοκιμών και αναλύσεων, υπηρεσίες χημικών τεχνικών δοκιμών και αναλύσεων σε βιομηχανικές διαδικασίες
                  </h4>
                }
              </div>
            </div>
          </div>
        </li>

      </ul>
    </section>
  )
}

export default Hero