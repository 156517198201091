import React from "react"
import { ESPA } from "./images"


const Footer = () => (
  <footer className="footer-2">
    <div className="container">
      <div className="row">

        <div className="col-md-3">
          <div className="footer-lower" style={{ marginTop: "1rem" }}>

            <h5 style={{ fontWeight: 900, margin: 0, marginBottom: 10 }}>Επικοινωνία</h5>

            <div style={{ marginBottom: 10 }}>
              <span style={{ display: "inline-block", marginRight: 10 }}>Διεύθυνση:</span>
              <span>Μπουκαούρη 74</span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ display: "inline-block", marginRight: 10 }}>Τηλέφωνο:</span>
              <span>
                <a href="tel:+302610623839">2610623839</a>
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ display: "inline-block", marginRight: 10 }}><span className="fab fa-linkedin-in"/></span>
              <span>
                <a href="https://www.linkedin.com/in/sofia-pappa-ba5781128/">Sofia Pappa</a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-xs-4">

        </div>
        <div className="col-md-7 text-right">
          <img style={{ marginTop: "3rem" }} {...ESPA}/>
          <a href={require("../../static/assets/webpraxi.pdf")} target="_blank" rel="noreferrer noopener">
            <img style={{
              marginTop: "3rem",
              position: "fixed",
              bottom: 0,
              width: 500,
              maxWidth: "100vw",
              // left: 0,
              right: "50%",
              transform: "translateX(50%)",
              boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)",

            }} {...ESPA}
            />
          </a>
          <div>
          </div>
        </div>
      </div>

    </div>
  </footer>
)

export default Footer